import React from "react";
import styled from "styled-components";

export function SocialMediaIcons() {
    return <Container>
        <SocialMediaIcon href="https://instagram.com/tom_allton" image={"instagram.png"}></SocialMediaIcon>
        <SocialMediaIcon href="https://www.snapchat.com/add/tomallton" image={"snapchat.png"}></SocialMediaIcon>
        <SocialMediaIcon href="ttps://youtube.com/tomallton" image={"youtube.png"}></SocialMediaIcon>
        <SocialMediaIcon href="https://twitter.com/tom_allton" image={"twitter.png"}></SocialMediaIcon>
        <SocialMediaIcon href="https://github.com/tomallton" image={"github.png"}></SocialMediaIcon>
    </Container>
}

const Container = styled.div`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
`

interface SocialMediaIconProps {
    href: string;
    image: string;
}

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({href, image}) => (
    <a href={href} target='_blank' rel="noopener noreferrer"><SocialMediaIconImage
        src={process.env.PUBLIC_URL + "/social-media-icons/" + image}
        alt={image}/></a>
)

const SocialMediaIconImage = styled.img`
  width: 50px;
  padding: 0;
  margin: 12px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`
import React from "react";
import styled, {createGlobalStyle} from "styled-components";

export function Contact() {
    return <>
        <GlobalStyles/>
        <BottomHeading>Contact</BottomHeading>
        <form id="contact" action="https://formspree.io/f/mzbnkqjg" method="POST" acceptCharset="UTF-8">
            <Input id="name" type="text" name="name" placeholder="Your Name"/><br/>
            <Input id="email" type="text" name="email" placeholder="Email Address"/><br/>
            <TextInput id="message" rows={5} name="message" form="contact" placeholder="Message"/><br/>
            <Submit type="submit" value="Send Message"/>
        </form>
    </>
}

const GlobalStyles = createGlobalStyle`
  input, textarea {
    font-family: "Proxima Nova Alt Light", sans-serif;
    color: #606060;
    background: #f5f5f5;
    font-weight: 900;
    width: 500px;
    box-sizing: border-box;
    border: none;
    margin: 10px;
    padding-left: 15px;
    font-size: 15px;
    letter-spacing: 1px;
  }

  ::placeholder {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
  }

  ::-webkit-input-placeholder {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
  }

  ::-moz-placeholder {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
  }

  :-ms-input-placeholder {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
  }

  :-moz-placeholder {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
  }
`;

const Input = styled.input`
  height: 50px;
`

const TextInput = styled.textarea`
  resize: none;
  padding-top: 15px;
`

const Submit = styled(Input)`
  text-transform: uppercase;
  color: white;
  background: black;
  margin-top: 15px;
  padding-left: 0;
  font-size: 13px;
  font-weight: 900;
  cursor: pointer;
`

const BottomHeading = styled.h5`
  color: black;
  margin: 30px 0;
  font-size: 50px;
  letter-spacing: 4px;
`;
import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import "./App.css";
import styled, {createGlobalStyle} from "styled-components";

// @ts-ignore
import oswaldLightFont from "./fonts/Oswald-Light.ttf";
// @ts-ignore
import proximaNovAltLightFont from "./fonts/Proxima-Nova-Alt-Light.otf";
import {SocialMediaIcons} from "./components/SocialMediaIcons";
import {Contact} from "./components/Contact";
import {Music} from "./components/Music";

function App() {
    return (<Router>
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/music" element={<Music/>}/>
        </Routes>
    </Router>)
}

const MainPage = () => {
    return (<Container>
        <GlobalStyles/>
        <BackgroundImage/>
        <Heading>Tom Allton</Heading>
        <SubHeading>Programmer / Designer / Creative</SubHeading>
        <SocialMediaIcons/>
        <Bottom>
            <BottomHeading>About</BottomHeading>
            Hi, I'm Tom Allton. I'm a software engineer at{" "}
            <Link href="https://www.improbable.io">Improbable</Link>,
            working on building the most realistic and sophisticated military simulations ever experienced.
            I graduated with a BSc degree in Computer Science from the{" "}
            <Link href="https://warwick.ac.uk/">University of Warwick</Link> in June 2020.
            Whilst in secondary school, I joined{" "}
            <Link href="https://www.lucillegames.com/">Lucille Games LLC</Link> and created the{" "}
            <Link href="https://www.pokefind.co/">PokéFind Network</Link>.
            With over 1,200,000 unique players, it continues to grow using the technology I developed.
            <Contact/>
        </Bottom>
        <Footer>
            Copyright © Tom Allton {new Date().getFullYear()}. All Rights Reserved.
        </Footer>
    </Container>);
}

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    text-align: center;
    height: 100%;
    color: white;
    font-weight: bold;
    font-family: "Oswald Light", sans-serif;
  }

  img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  a:link {
    color: black;
  }

  a:visited {
    color: black;
  }

  a:hover {
    color: black;
  }

  a:active {
    color: black;
  }

  @font-face {
    font-family: "Oswald Light";
    src: url(${oswaldLightFont}) format("truetype");
  }

  @font-face {
    font-family: "Proxima Nova Alt Light";
    src: url(${proximaNovAltLightFont}) format("opentype");
  }
`;

const Container = styled.div`
  height: 100vh;
  -webkit-user-drag: none;
`

const BackgroundImage = styled.div`
  width: 100%;
  height: 90%;
  background-image: url(${process.env.PUBLIC_URL + "/background.jpg"});
  background-size: cover;
  background-position: center;
`

const Heading = styled.h1`
  text-align: center;
  font-size: 90px;
  letter-spacing: 20px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
`

const SubHeading = styled.h3`
  font-size: 25px;
  font-weight: 300;
  margin: 0;
  letter-spacing: 3px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
`

const Bottom = styled.div`
  color: black;
  margin-bottom: 0;
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: 300;
  margin-left: 20%;
  margin-right: 20%;
`

const BottomHeading = styled.h5`
  color: black;
  margin: 30px 0;
  font-size: 50px;
  letter-spacing: 4px;
`;

interface LinkProps {
    href: string;
    children: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({href, children}) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
    </a>
);

const Footer = styled.footer`
  font-family: "Proxima Nova Alt Light", sans-serif;
  color: black;
  float: left;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 90px;
`

export default App;

import React from "react";
import styled from "styled-components";

// TODO: don't hardcore music files
const MUSIC_FILES = [
    "18 Minute Heavenly Playboi Carti Mix (528Hz).mp4", "Kanye West - Future Bounce.mp3", "arm_and_leg.mp3", "cancun.mp3", "city_in_the_sky.mp3", "dj_windows_xp_the_day_i_met_susan.mp3", "illusion.mp3", "long_time.mp3", "magnolia.mp3", "miss_the_rage.mp3", "miss_the_rage_remake.mp3", "molly.mp3", "molly_slowed.mp3", "molly_slowed_639hz.mp3", "pissy.mp3", "playboi carti mix.mp4", "shawty.mp3", "yandi.mp4"
]

export function Music() {
    MUSIC_FILES.sort()
    return (<Container>
        <Heading>Music</Heading>
        {MUSIC_FILES.map(file => <TrackName><a href={"/music/" + file}>{file}</a><br/></TrackName>)}
    </Container>)
}

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Heading = styled.h1`
  font-size: 40px;
`

const TrackName = styled.div`
  margin: 5px;
  font-size: 20px;
`